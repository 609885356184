  @import url('https://fonts.googleapis.com/css2?family=Playfair:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Poppins:wght@400;500;600;700&display=swap');


  body {
  margin: 0;
  font-family: 'Playfair', serif;
  background: var(--bg);
  transition: all 3ms ease;
  color: var(--black);
  scroll-behavior: smooth;
}


